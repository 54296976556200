import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import EventList from '../components/event-list'
import styles from './events.module.css'

export const ReleasingSchedulesTemplate = ({ schedule, events }) => {
  return (
    <div className="container flex flex-col items-center">
      <h1 className={styles.title}>{schedule}</h1>
      <EventList events={events} />
    </div>
  )
}

const ReleasingSchedulesPage = ({ data, pageContext }) => {
  const events = data.events.edges.map(e => ({
    ...e.node.frontmatter,
    ...e.node.fields,
  }))

  return (
    <ReleasingSchedulesTemplate
      schedule={pageContext.schedule}
      events={events}
    />
  )
}

export default ReleasingSchedulesPage

ReleasingSchedulesPage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export const pageQuery = graphql`
  query EventByReleasingSchedules($schedule: String!) {
    events: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "films-and-events" } }
        frontmatter: { releasingSchedules: { in: [$schedule] } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            veeziFilmId
            eventTypes
            title
            coverImage {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 232, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            blurbHtml
            rating
            platforms
            vodURL
            vodCallToAction
          }
        }
      }
    }
  }
`
